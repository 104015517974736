
.tiblock {
  align-items: center;
  display: flex;
  height: 25px;
  padding: 8px 12px;
  background: #FFFFFF;
  border: 1.5px solid #F2F2F2;
  border-radius: 10px;
}

.ticontainer .tidot {
  background-color: #90949c;
}

.tidot {
  animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 50%;
  display: inline-block;
  height: 6px;
  margin-right: 2px;
  width: 6px;
}

@keyframes mercuryTypingAnimation{
0%{
-webkit-transform:translateY(0px)
}
28%{
-webkit-transform:translateY(-5px)
}
44%{
-webkit-transform:translateY(0px)
}
}

.tidot:nth-child(1){
animation-delay:200ms;
}
.tidot:nth-child(2){
animation-delay:300ms;
}
.tidot:nth-child(3){
animation-delay:400ms;
}