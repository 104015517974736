.menu-suggest{
    position: absolute;
    width: 300px;
    left: 16px;
    bottom: 42px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family:  'Roboto'
}

.item-question-suggest{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}