/* body{
  height:0px;
  width: 360px !important;

} */

.chat-bot-ftech{
  bottom: 12px;
  right: 40px;
  /* bottom: 0px;
  right: 0px; */
  position: fixed;
}

.icon-close-chat{
  border-radius: 50%;
  background-color: #F6F6F6;
  padding: 5px;
  cursor: pointer;
  max-width: 30px;
}


@media screen and (max-width: 500px) {
  body{
    height:0px;
    width: 100% !important;
  
  }

  .chat-bot-ftech{
    bottom: 0px;
    right:0px;
    position: fixed;
  }
}

.small-logo-chat:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #2dd5fa;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: live 2s ease-in-out infinite;
          animation: live 2s ease-in-out infinite;
  z-index: -1;
  pointer-events: none;
}

@-webkit-keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}