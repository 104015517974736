.self-chat{
    height:fit-content;
}

.self-chat-content{
    background-color: #2F80ED;
    height:fit-content;
    padding: 8px 12px;
    border: 1.5px solid #F2F2F2;
    border-radius: 10px;
    max-width: 240px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    word-break: break-word;
}

