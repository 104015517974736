.require-phone{
    font-family: 'Mulish';
    min-width:360px;
    height:calc(100vh - 100px)  !important;
    max-height: 620px;
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.require-phone p{
    margin: 0px
}

.require-phone input{
    width: 300px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
}

.require-phone input{
    width: 300px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
}

.require-phone input:focus {
    outline: 1px solid #3DBBED;
  }

.require-phone button{
    width:300px;
    height:40px;
    border-radius: 5px;
    padding: 12px 48px;
    margin-top: 10px;
    font-weight: 500px;
    font-size:16px;
    line-height: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #F7F7FC;
}


.top-1{
    top: 4px;
}

.right-1{
    right: 4px;
}

@media screen and (max-width: 500px) {
    .require-phone {
        max-height: 750px !important;
        width: 100vw !important;
        position: fixed;
        bottom:  0 !important;
        right: 0px;
        height: calc(100vh - calc(100vh - 100%)) !important;
        border-radius:0px;
    }
  }